import React from "react";


export const BG1 = () => {
  return (
    <svg
      width="1027"
      height="858"
      viewBox="0 0 1027 858"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.0979 654.441C44.0979 654.441 141.652 566.562 307.506 589.556C435.148 607.257 596.787 630.55 826.594 577.253C984.039 540.739 1026.39 633.06 1027 704.001C1027.64 780.021 940.908 837.48 766.419 844.433C597.612 851.154 160.977 874.457 65.4394 838.156C-30.0983 801.845 -6.40474 701.422 44.0979 654.441Z"
        fill="#B6D4F0"
      />
      <path
        d="M627.409 81.2729C627.409 81.2729 592.376 167.494 621.704 229.687C651.033 291.88 733.323 303.897 777.321 308.131C821.318 312.373 907.166 302.154 947.597 253.553C988.036 204.952 1002.99 134.276 973.666 87.635C944.329 40.9937 888.738 0.140613 809.756 0.000633429C708.973 -0.18134 641.327 38.873 627.409 81.2729Z"
        fill="#B6D4F0"
      />
    </svg>
  );
};

export const BG2 = () => {
  return (
    <svg
      className="absolute inset-0 items-center justify-center w-full h-full"
      width="1404"
      height="484"
      viewBox="0 0 1404 484"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1404" height="484" rx="190" fill="#B6D4F0" />
    </svg>
  );
};

export const Star = () => {
  return (
    <svg
      className="absolute z-50  inset-10"
      width="93"
      height="92"
      viewBox="0 0 93 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.077 12.3614L78.8358 35.5279C78.6017 36.8054 78.8629 38.1369 79.5562 39.2345L92.1897 59.1171C94.2158 62.302 92.2528 66.5214 88.5068 67.0162L65.1398 70.1471C63.8431 70.318 62.6635 70.9748 61.8351 71.9824L46.8153 90.1287C44.4021 93.0346 39.7917 92.4768 38.1528 89.0671L27.9506 67.8349C27.3833 66.6563 26.4018 65.7387 25.1861 65.2619L3.27784 56.5891C-0.233972 55.2036 -1.12543 50.6423 1.61198 48.0332L18.6758 31.7852C19.6213 30.8856 20.1886 29.662 20.2696 28.3665L21.7464 4.85819C21.9805 1.08858 26.0506 -1.16059 29.3733 0.629749L50.12 11.8216C51.2636 12.4424 52.6053 12.6043 53.866 12.2804L76.7017 6.42361C80.3666 5.48795 83.7614 8.65478 83.086 12.3704L83.077 12.3614Z"
        fill="#D9993A"
      />
    </svg>
  );
};

export const DogIcon = () => {
  return (
    <svg
      className="absolute z-50  right-0"
      width="145"
      height="171"
      viewBox="0 0 145 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M142.272 74.3254C144.773 62.2235 145.516 47.8805 143.024 30.4791C143.47 23.9843 139.029 14.0795 133.18 7.699C133.18 7.35624 133.057 7.0047 132.708 6.68831C129.193 3.56835 125.093 0.123216 120.083 0.53628C118.011 0.703264 115.956 1.43272 114.47 2.94436C112.791 4.64056 112.162 7.06622 112.302 9.41278C112.599 14.4926 115.318 19.2824 117.757 23.6152C118.92 25.6717 120.048 27.737 121.097 29.8375C121.526 31.0064 121.954 32.1753 122.365 33.353C126.395 44.7869 129.455 56.4758 128.013 68.4722C131.728 72.0316 136.869 73.93 142.281 74.3167L142.272 74.3254Z"
        fill="#755A58"
      />
      <path
        d="M128.004 68.4809C127.007 76.7246 123.895 85.109 117.495 93.6603C119.244 88.7035 111.253 76.1182 114.627 73.2883C114.592 73.1213 114.549 72.9631 114.505 72.7961C118.186 68.5688 120.258 63.2165 121.543 57.8028C123.038 51.4486 123.799 44.6111 123.134 38.0987C122.977 36.508 122.68 34.926 122.146 33.4495C121.622 31.973 120.861 30.5932 119.759 29.3892C117.827 23.5448 99.2487 31.9379 95.7166 33.845C91.0829 37.1407 86.8863 40.4804 82.4275 42.4403C82.2264 42.6951 82.0165 42.95 81.7892 43.1873C79.4374 45.6921 76.2113 44.945 73.2387 44.2332C72.854 44.1365 72.4606 44.0486 72.0584 43.9695C71.5863 43.8992 71.1142 43.8025 70.6334 43.6795C68.4564 43.2928 66.2445 43.0731 64.0325 43.0467C66.0958 59.5517 61.7332 76.6368 48.5227 87.4555C37.5505 97.0088 19.2692 101.421 6.84561 91.1292C5.40305 89.6703 4.01294 88.1586 2.75397 86.5503C-0.891798 102.563 2.83265 121.309 20.8254 125.097C17.8703 130.968 16.8562 137.7 17.9228 144.186C-4.63373 150.602 13.4115 180.131 29.9529 165.947C32.8381 168.636 37.8565 167.915 40.252 164.927C40.7066 168.908 46.8616 164.435 47.4124 162.22C56.5574 162.282 65.7111 162.343 74.8649 162.414C79.2013 166.992 87.4371 167.713 92.7178 164.048C95.192 168.197 100.735 169.822 105.071 167.88C112.153 170.42 116.892 161.702 115.851 155.558C100.036 132.875 133.092 118.857 142.29 74.3341C136.878 73.9474 131.737 72.0491 128.021 68.4897L128.004 68.4809Z"
        fill="#FAF1DD"
      />
      <path
        d="M48.5226 87.4557C61.7418 76.6281 66.0957 59.5518 64.0324 43.0468C63.6128 43.0468 63.1844 43.0292 62.7647 43.0468C59.4774 43.1171 56.0852 44.1014 52.964 42.5898C50.8395 41.5527 49.6242 39.4698 47.9106 37.967C39.4388 32.3599 32.1211 23.0703 21.1138 20.4074C21.0526 20.2492 20.9739 20.1085 20.8515 19.9679C19.8549 18.8166 18.1762 18.5705 16.7949 19.0891C15.2386 19.6779 14.3206 21.0226 13.7349 22.5254C13.0879 24.1865 12.677 25.9618 12.2398 27.6931C11.7415 29.6178 11.3044 31.5601 10.9197 33.5112C9.52082 40.6739 8.70773 48.3815 9.98419 55.6233C10.4388 58.1808 11.3393 60.7559 12.9393 62.7948C10.6574 66.82 8.78642 71.144 7.06408 74.7298C5.18437 78.2628 3.70683 82.3231 2.74512 86.568C4.01283 88.1763 5.3942 89.688 6.83677 91.1469C19.2691 101.438 37.5504 97.0265 48.5139 87.4732L48.5226 87.4557Z"
        fill="#E7B387"
      />
      <path
        d="M108.805 61.7138C110.807 63.2958 112.101 65.282 113.019 67.4704C113.421 66.7673 113.823 66.0642 114.216 65.405C119.943 55.8606 123.641 42.3262 116.621 32.4917C115.607 32.7378 114.61 33.0717 113.648 33.4848C107.126 36.306 101.976 42.0976 98.7679 48.5836C97.7712 51.5542 97.2466 54.6038 97.3603 57.4865C101.469 57.9435 105.482 59.1036 108.796 61.7138H108.805Z"
        fill="#EAAC8E"
      />
      <path
        d="M32.6546 41.8076C32.3573 39.3731 31.448 37.0266 30.3639 34.847C29.2099 32.5268 27.8635 30.2506 26.2286 28.238C24.5412 26.1463 22.609 24.4589 20.1436 23.3691C19.042 22.8769 17.8966 22.4726 16.7601 22.0771C16.7426 22.1211 16.7338 22.165 16.7076 22.2002C15.1601 25.1883 13.9012 28.3171 12.9569 31.5513C12.2051 34.1088 11.663 36.7366 11.3133 39.3995C11.2171 42.3613 11.3395 45.3318 11.6105 48.2584C11.9428 51.853 12.7296 55.2981 13.5864 58.8047C13.6913 59.2354 13.77 59.7012 13.8487 60.1846C16.8388 56.4142 20.6594 53.637 25.1445 51.809C27.199 50.9741 29.3323 50.3589 31.5005 49.8755C32.4884 47.2917 33.0043 44.5848 32.6633 41.7988L32.6546 41.8076Z"
        fill="#EAAC8E"
      />
      <path
        d="M15.571 55.8073C14.6093 58.646 13.1492 61.3353 11.8815 64.0422C10.6488 66.6788 9.33736 69.2802 8.06091 71.8992C5.43806 77.2779 2.87641 82.7444 1.36389 88.5624C-0.0524463 94.0114 -0.542045 99.7855 0.769381 105.305C1.84475 109.848 4.08292 114.076 7.08171 117.626C10.439 121.608 14.9503 124.895 19.9599 126.389C21.2626 126.775 21.726 124.851 20.5107 124.385C12.1613 121.159 5.52549 114.375 3.12995 105.612C0.131153 94.6441 4.87851 83.4826 9.56468 73.7448C10.9548 70.8622 12.3536 67.9883 13.6214 65.0529C14.8891 62.1263 15.7284 59.1382 16.6114 56.0885C16.8037 55.4206 15.7983 55.1481 15.5798 55.7985L15.571 55.8073Z"
        fill="#49312C"
      />
      <path
        d="M52.2908 43.0818C51.163 40.4364 49.1172 38.1426 47.2287 35.9981C45.2965 33.7922 43.1808 31.7532 40.9514 29.8461C36.5275 26.0846 31.6227 22.9207 26.3596 20.4774C25.0394 19.8622 23.7017 19.2998 22.3466 18.7725C20.974 18.2451 19.5576 17.7266 18.0801 17.6915C14.9764 17.6212 13.2978 20.0029 12.3448 22.657C10.0367 29.0376 8.07828 35.7257 7.40508 42.5017C6.72314 49.3129 7.57119 56.9062 11.3831 62.7507C12.1262 63.8844 13.8835 62.8737 13.2103 61.6785C10.3427 56.5986 9.2061 51.0267 9.39845 45.2086C9.61702 38.8105 11.3656 32.553 13.2628 26.4801C13.9272 24.3444 14.5305 20.3896 17.1533 19.8007C18.5697 19.4843 20.0909 20.0908 21.3936 20.5829C22.6351 21.0487 23.8591 21.5584 25.0656 22.1121C29.9529 24.3444 34.5603 27.2183 38.7307 30.6195C41.0825 32.5354 43.277 34.6183 45.3577 36.8243C47.456 39.0566 49.1871 41.6404 51.3728 43.7761C51.8712 44.2682 52.5269 43.653 52.2821 43.073L52.2908 43.0818Z"
        fill="#49312C"
      />
      <path
        d="M30.4337 50.1828C29.2272 48.1878 27.6885 46.4125 25.9399 44.8832C25.0744 44.1274 24.1564 43.4155 23.2034 42.7652C22.2067 42.0797 21.1838 41.4117 20.0472 41.0163C19.0506 40.6735 18.5959 42.0885 19.3653 42.6334C20.2658 43.2661 21.2538 43.7583 22.1805 44.3559C23.081 44.9448 23.9465 45.5951 24.7596 46.2894C26.4295 47.7132 27.9508 49.3215 29.3671 51.0001C29.8654 51.5977 30.8272 50.8243 30.4337 50.174V50.1828Z"
        fill="#49312C"
      />
      <path
        d="M26.1149 49.6289C24.9783 49.4268 23.8417 49.2158 22.7052 49.0137C22.1456 48.9082 21.5861 48.8379 21.0178 48.7588C20.4058 48.6709 19.6452 48.4336 19.0507 48.6446C18.3512 48.8994 18.2551 49.7431 18.8496 50.165C19.3479 50.5253 20.1085 50.499 20.703 50.5693C21.2888 50.6484 21.8746 50.7275 22.4604 50.7802C23.6144 50.8857 24.7597 50.9911 25.9138 51.1054C26.7444 51.1845 26.9454 49.7783 26.1149 49.6289Z"
        fill="#49312C"
      />
      <path
        d="M22.303 51.4572C21.5423 51.5627 20.773 51.6682 20.0123 51.7736C19.6364 51.8264 19.2605 51.8703 18.8933 51.9318C18.5086 51.9933 18.1152 52.0373 17.748 52.1691C17.3283 52.3185 17.0573 52.6876 17.1185 53.1359C17.1797 53.5753 17.5381 53.8917 17.984 53.9005C18.4299 53.918 18.867 53.8214 19.3042 53.7599C19.6889 53.7071 20.0823 53.6368 20.467 53.5665C21.2363 53.4347 22.0145 53.3028 22.7838 53.171C23.2472 53.0919 23.5445 52.4943 23.4046 52.0724C23.2385 51.5627 22.8013 51.3781 22.3117 51.4484L22.303 51.4572Z"
        fill="#49312C"
      />
      <path
        d="M39.2378 167.985C42.2017 168.609 45.6288 168.249 47.6484 165.736C49.6593 163.24 49.8167 159.865 48.8025 156.912C46.7742 151.015 41.6946 146.181 36.0817 143.729C29.9704 141.057 22.9149 141.031 16.7775 143.65C11.0771 146.084 6.25983 150.962 5.51669 157.36C5.11452 160.823 6.09372 164.426 8.48051 167.001C10.6837 169.374 13.971 170.789 17.2059 170.385C18.5698 170.218 18.5872 168.047 17.2059 168.214C11.8378 168.873 7.64994 164.189 7.59749 159.012C7.54503 153.449 11.3132 148.932 15.9994 146.427C21.0964 143.694 27.2864 143.175 32.7944 144.889C37.7166 146.418 42.053 149.793 44.8332 154.152C46.4682 156.71 47.8495 159.988 46.678 163.02C45.4365 166.201 42.0618 166.228 39.2203 166.878C38.6783 167.001 38.6608 167.88 39.2203 167.994L39.2378 167.985Z"
        fill="#49312C"
      />
      <path
        d="M14.583 164.162C14.8453 165.955 15.4573 167.537 16.8212 168.794C18.1763 170.042 19.8549 170.824 21.6909 170.974C25.2755 171.272 29.0349 168.882 30.2414 165.472C30.6786 164.25 28.6677 163.371 28.1344 164.575C26.9891 167.124 24.681 168.943 21.7959 168.759C20.5019 168.68 19.1817 168.1 18.1851 167.282C17.1272 166.421 16.0343 165.199 15.6234 163.872C15.4136 163.187 14.4868 163.486 14.5918 164.162H14.583Z"
        fill="#49312C"
      />
      <path
        d="M28.8251 165.92C29.1049 166.298 29.2448 166.72 29.5595 167.08C29.8218 167.379 30.1278 167.643 30.4425 167.889C31.1332 168.451 31.8764 168.856 32.6982 169.172C34.3418 169.805 36.1953 169.901 37.8914 169.418C39.5875 168.935 41.0476 167.906 42.053 166.447C42.9623 165.129 43.7054 162.906 43.0934 161.324C42.6563 160.199 40.9951 160.313 40.9427 161.614C40.9165 162.229 41.0389 162.818 40.899 163.424C40.7591 164.066 40.523 164.655 40.1646 165.208C39.4651 166.289 38.3461 167.001 37.1221 167.335C35.8806 167.669 34.5691 167.608 33.3452 167.212C32.6982 167.001 32.0774 166.693 31.4917 166.36C31.2032 166.193 30.9234 166.008 30.6524 165.823C30.3551 165.621 29.9442 165.481 29.6819 165.252C29.2535 164.874 28.4579 165.384 28.8426 165.903L28.8251 165.92Z"
        fill="#49312C"
      />
      <path
        d="M28.4316 160.568C28.5016 161.438 28.6327 162.317 28.6677 163.195C28.6852 163.644 28.6677 164.092 28.624 164.531C28.5803 164.971 28.4404 165.41 28.4404 165.85C28.4404 166.5 29.4196 166.834 29.7168 166.201C29.9442 165.718 29.9966 165.164 30.0403 164.628C30.084 164.153 30.084 163.67 30.0578 163.195C29.9966 162.176 29.7955 161.227 29.2535 160.348C29.0436 160.005 28.4054 160.111 28.4404 160.568H28.4316Z"
        fill="#49312C"
      />
      <path
        d="M111.768 64.974C111.978 67.8479 112.109 70.6954 112.652 73.5341C113.194 76.408 113.876 79.2555 114.575 82.103C115.939 87.675 117.285 93.2909 117.635 99.0387C117.967 104.444 117.486 110.06 115.213 115.034C113.377 119.059 110.545 122.583 107.074 125.308C103.087 128.428 98.4444 130.212 93.4785 130.994C92.3332 131.17 92.5955 133.13 93.7495 133.033C102.921 132.321 111.515 126.204 116.026 118.277C121.779 108.17 120.118 96.0945 117.556 85.2845C116.769 81.98 115.913 78.693 115.117 75.3973C114.706 73.7011 114.339 71.9873 113.937 70.2823C113.526 68.5246 113.054 66.7669 112.844 64.974C112.765 64.2973 111.716 64.2709 111.768 64.974Z"
        fill="#49312C"
      />
      <path
        d="M85.5399 46.6675C84.0012 45.9116 82.5149 45.2261 80.88 44.6724C79.3325 44.1539 77.7588 43.7145 76.1676 43.3278C72.8628 42.5456 69.5055 42.0183 66.122 41.7458C59.4163 41.2097 52.5007 41.6579 45.9611 43.2926C44.9556 43.5475 45.384 45.0679 46.3895 44.8394C52.8417 43.4157 59.3638 42.8356 65.9647 43.3541C72.5393 43.8639 78.8953 45.5777 85.1465 47.6078C85.6973 47.7836 86.082 46.9135 85.5486 46.6499L85.5399 46.6675Z"
        fill="#49312C"
      />
      <path
        d="M80.3118 45.6476C82.6286 44.1887 84.6395 42.2552 86.8689 40.6557C89.1245 39.0474 91.4676 37.5621 93.8894 36.2438C98.7242 33.6248 103.874 31.621 109.216 30.3203C111.76 29.6963 114.601 28.9317 117.242 29.0723C118.509 29.1426 119.611 29.6435 120.205 30.8212C120.791 31.9813 120.879 33.3611 121.001 34.6355C121.639 41.1303 122.225 47.7657 121.333 54.2692C120.476 60.5443 118.16 66.4063 113.937 71.1609C113.036 72.1716 114.453 73.6305 115.432 72.6638C119.646 68.4892 122.041 62.5569 123.117 56.8003C124.411 49.8925 123.921 42.844 123.195 35.901C122.916 33.2381 122.872 29.9072 120.529 28.1231C118.256 26.3918 115.082 27.0948 112.503 27.5519C106.636 28.5977 100.901 30.4785 95.5505 33.115C92.578 34.574 89.6928 36.2614 86.9913 38.1861C84.3247 40.0932 81.4134 42.1409 79.5162 44.8566C79.1752 45.34 79.8309 45.9464 80.303 45.6476H80.3118Z"
        fill="#49312C"
      />
      <path
        d="M100.324 57.363C102.055 56.0183 103.865 54.7791 105.788 53.7421C106.724 53.2323 107.694 52.7841 108.682 52.3974C109.723 51.9931 110.807 51.7207 111.83 51.29C112.241 51.1143 112.52 50.7979 112.459 50.3233C112.407 49.9278 112.031 49.506 111.594 49.5587C109.312 49.8399 107.021 50.9649 105.028 52.0634C102.991 53.1884 101.094 54.5946 99.4936 56.2995C98.9865 56.8357 99.6859 57.8639 100.315 57.3718L100.324 57.363Z"
        fill="#49312C"
      />
      <path
        d="M103.865 58.189C105.019 58.3296 106.182 58.4702 107.336 58.6109C107.904 58.6812 108.472 58.7251 109.041 58.7691C109.705 58.8218 110.343 58.9448 110.964 58.6724C111.567 58.4087 111.436 57.5299 110.964 57.231C110.44 56.8971 109.705 56.9498 109.102 56.9059C108.516 56.8619 107.922 56.818 107.336 56.8004C106.182 56.7652 105.019 56.7213 103.865 56.6862C103.463 56.6686 103.113 57.0377 103.113 57.442C103.113 57.8902 103.454 58.1451 103.865 58.1978V58.189Z"
        fill="#49312C"
      />
      <path
        d="M106.514 60.9314C107.231 61.2214 107.948 61.5026 108.665 61.7927C109.006 61.9333 109.355 62.0739 109.696 62.2057C110.072 62.3463 110.44 62.5045 110.833 62.5749C111.27 62.6539 111.69 62.4958 111.873 62.0651C112.048 61.6608 111.891 61.195 111.515 60.9577C111.13 60.7205 110.702 60.5798 110.282 60.4129C109.924 60.2722 109.565 60.1404 109.198 60.0086C108.464 59.7361 107.729 59.4725 106.986 59.2C106.549 59.0418 105.989 59.3758 105.893 59.824C105.78 60.3425 106.051 60.738 106.514 60.9226V60.9314Z"
        fill="#49312C"
      />
      <path
        d="M83.6077 165.639C81.1073 165.068 78.0473 165.085 76.561 162.607C75.1185 160.19 75.7392 157.175 76.9282 154.794C79.2538 150.145 83.3717 146.33 88.1453 144.335C93.4609 142.12 99.5809 142.138 104.914 144.3C109.801 146.278 114.138 150.285 115.064 155.681C115.572 158.626 114.942 161.877 112.905 164.136C111.043 166.201 108.394 167.335 105.622 166.992C104.25 166.825 104.258 168.996 105.622 169.163C108.481 169.523 111.314 168.46 113.482 166.614C115.816 164.619 117.11 161.737 117.364 158.696C117.915 152.183 113.613 146.488 108.166 143.465C102.361 140.24 95.1658 139.581 88.8797 141.69C83.1007 143.623 78.0123 147.763 75.1359 153.185C73.4486 156.367 72.6355 160.216 74.4977 163.512C76.3512 166.799 80.1718 167.484 83.599 166.764C84.1586 166.65 84.1411 165.771 83.599 165.648L83.6077 165.639Z"
        fill="#49312C"
      />
      <path
        d="M107.222 162.642C106.829 163.943 105.762 165.129 104.739 165.99C103.725 166.843 102.379 167.449 101.05 167.528C98.1646 167.713 95.8478 165.894 94.7112 163.345C94.1779 162.159 92.167 163.002 92.6042 164.241C93.8107 167.643 97.5701 170.051 101.155 169.743C102.991 169.585 104.661 168.812 106.024 167.564C107.388 166.307 108 164.734 108.263 162.932C108.359 162.264 107.432 161.965 107.231 162.642H107.222Z"
        fill="#49312C"
      />
      <path
        d="M93.1725 164.039C92.9277 164.259 92.5517 164.373 92.2807 164.558C92.0009 164.742 91.7299 164.935 91.4414 165.102C90.8556 165.436 90.2349 165.753 89.5967 165.973C88.3464 166.394 87.0088 166.473 85.7236 166.131C84.5433 165.814 83.4242 165.12 82.7335 164.092C82.3663 163.547 82.1128 162.958 81.9642 162.316C81.8155 161.666 81.9292 161.06 81.903 160.409C81.8505 159.109 80.1894 158.977 79.7522 160.119C79.149 161.675 79.8659 163.846 80.7314 165.155C81.7281 166.649 83.2231 167.713 84.9455 168.205C86.6066 168.679 88.4251 168.6 90.0425 167.994C90.9081 167.678 91.6775 167.256 92.3944 166.676C92.7004 166.421 93.0151 166.166 93.2774 165.867C93.5921 165.507 93.7408 165.085 94.0118 164.707C94.3877 164.188 93.6009 163.67 93.1725 164.057V164.039Z"
        fill="#49312C"
      />
      <path
        d="M93.6008 159.109C93.0587 159.979 92.8577 160.937 92.7965 161.956C92.7702 162.431 92.7702 162.914 92.814 163.389C92.8577 163.925 92.9101 164.478 93.1374 164.962C93.4347 165.595 94.4226 165.261 94.4139 164.61C94.4139 164.171 94.274 163.723 94.2303 163.292C94.1866 162.844 94.1691 162.404 94.1866 161.956C94.2128 161.077 94.3439 160.207 94.4226 159.328C94.4576 158.871 93.8281 158.766 93.6095 159.109H93.6008Z"
        fill="#49312C"
      />
      <path
        d="M106.531 128.832C106.531 129.579 106.357 130.308 106.374 131.064C106.392 131.811 106.418 132.55 106.496 133.288C106.636 134.65 106.811 136.012 107.091 137.357C107.371 138.719 107.712 140.073 108.105 141.408C108.481 142.692 108.822 144.353 109.679 145.407C110.125 145.961 111.086 145.504 111.06 144.827C111.008 143.535 110.282 142.147 109.915 140.908C109.53 139.624 109.198 138.324 108.909 137.014C108.63 135.705 108.35 134.395 108.14 133.068C108.026 132.383 107.904 131.706 107.799 131.02C107.685 130.291 107.414 129.553 107.344 128.832C107.292 128.331 106.549 128.305 106.549 128.832H106.531Z"
        fill="#49312C"
      />
      <path
        d="M119.401 92.412C127.917 78.1569 130.137 60.4215 126.999 44.2329C125.416 36.1034 122.426 28.2464 118.09 21.1979C115.808 17.4891 113.001 13.6485 113.22 9.06962C113.412 5.05323 116.385 2.44301 120.284 2.2233C128.441 1.77508 134.771 9.11357 137.796 15.9599C141.346 23.9839 142.403 33.1592 142.998 41.8424C144.135 58.3034 141.695 75.1776 136.441 90.7861C131.186 106.377 122.986 121.098 112.171 133.499C110.842 135.028 109.469 136.522 108.062 137.981C107.318 138.754 108.499 139.95 109.242 139.167C120.931 126.881 130.05 112.265 136.135 96.4108C142.194 80.6265 145.306 63.7084 144.991 46.7728C144.913 42.5191 144.668 38.2742 144.161 34.0469C143.61 29.4768 142.937 24.8628 141.704 20.4157C139.431 12.2247 134.343 4.02496 126.081 0.940158C122.723 -0.307824 118.579 -0.439654 115.423 1.4499C111.83 3.59432 110.763 7.84801 111.454 11.7765C112.258 16.3202 115.283 20.0642 117.521 23.9575C119.663 27.6927 121.429 31.63 122.802 35.7079C128.266 51.9317 127.724 70.2735 121.053 86.0666C120.232 88.0177 119.401 89.96 118.439 91.8495C118.107 92.4999 119.034 93.0536 119.419 92.4208L119.401 92.412Z"
        fill="#49312C"
      />
      <path
        d="M66.6816 99.5131C65.4051 101.06 64.1112 102.615 62.4326 103.714C60.7627 104.804 58.6382 105.366 56.741 104.725C55.6481 104.347 54.7039 103.6 53.8121 102.844C52.3171 101.561 50.8396 100.19 49.8604 98.4673C48.8899 96.7359 48.479 94.6003 49.1959 92.7547L50.6909 91.3749C55.3771 90.3817 60.3255 90.6893 64.8543 92.2713C66.3931 92.8074 68.0804 93.7038 68.4127 95.3034C68.7362 96.8502 67.6695 98.3003 66.6641 99.5131H66.6816Z"
        fill="#EAAC8E"
      />
      <path
        d="M40.7242 96.5338C40.0773 96.6656 39.4828 96.5953 38.8358 96.8326C38.1888 97.0699 37.5856 97.4127 37.096 97.9048C36.143 98.8716 35.802 100.225 35.7758 101.543C35.7233 104.092 36.5539 106.975 37.6905 109.242C38.8708 111.615 40.8292 113.636 43.3908 114.427C46.171 115.28 49.2485 114.665 51.7752 113.338C54.7652 111.773 57.4231 109.181 59.4601 106.518C60.1071 105.665 58.7607 104.742 58.0351 105.41C55.6657 107.599 53.7161 110.139 50.796 111.65C48.0857 113.065 44.6235 113.461 42.0182 111.597C39.7888 110.007 38.7046 107.133 38.1101 104.549C37.8129 103.257 37.5331 101.763 37.8478 100.454C38.0227 99.7153 38.4161 99.1089 39.0369 98.6606C39.3254 98.4497 39.6489 98.2827 39.9636 98.1245C40.2958 97.9488 40.663 97.65 41.004 97.5269C41.6248 97.2984 41.3887 96.3932 40.733 96.5338H40.7242Z"
        fill="#49312C"
      />
      <path
        d="M77.7676 100.946C78.3358 101.306 78.9828 101.956 79.4462 102.457C80.0057 103.073 80.268 103.802 80.2593 104.637C80.2418 106.105 79.5598 107.625 78.9129 108.917C77.7239 111.29 76.019 113.751 73.4224 114.674C70.5984 115.676 67.5122 114.559 65.2828 112.731C62.7386 110.64 61.3747 107.748 59.5125 105.112C58.8393 104.154 57.4405 104.962 57.9475 106.034C60.3081 111.061 64.6008 116.458 70.4935 116.985C76.2376 117.495 80.0145 112.16 81.7018 107.309C82.2089 105.867 82.4625 104.154 81.8242 102.712C81.5095 102.009 81.0286 101.438 80.4166 100.99C80.1281 100.77 79.8221 100.594 79.4899 100.436C79.1052 100.26 78.6768 100.225 78.2921 100.058C77.6976 99.7945 77.1993 100.585 77.7763 100.955L77.7676 100.946Z"
        fill="#49312C"
      />
      <path
        d="M53.0692 116.036C53.1216 116.264 53.1566 116.581 53.2615 116.862C53.3839 117.178 53.5325 117.468 53.6986 117.75C54.0309 118.294 54.4243 118.813 54.8614 119.27C55.3073 119.727 55.7969 120.131 56.3128 120.5C56.8548 120.878 57.4318 121.151 58.0438 121.397C58.5334 121.59 59.1629 121.186 59.2766 120.694C59.4077 120.114 59.1017 119.674 58.5772 119.455C58.3498 119.358 58.7782 119.542 58.5509 119.446C58.4897 119.419 58.4198 119.384 58.3586 119.358C58.2362 119.305 58.1225 119.244 58.0089 119.182C57.7728 119.059 57.5455 118.936 57.3182 118.795C57.0821 118.655 56.8548 118.497 56.6275 118.338C56.61 118.321 56.4439 118.198 56.5488 118.286C56.6537 118.374 56.4964 118.242 56.4701 118.224C56.3565 118.136 56.2516 118.04 56.1379 117.943C55.7445 117.591 55.3685 117.213 55.0363 116.8C54.9926 116.748 54.9489 116.686 54.9052 116.633C55.0101 116.765 54.8702 116.581 54.8614 116.563C54.7653 116.431 54.6691 116.299 54.5642 116.176C54.5205 116.124 54.4768 116.071 54.433 116.009C54.538 116.141 54.2232 115.746 54.3019 115.842C54.2232 115.755 54.1183 115.684 54.0658 115.605C53.7074 115.113 52.9205 115.394 53.0604 116.027L53.0692 116.036Z"
        fill="#49312C"
      />
      <path
        d="M45.1306 80.8899C45.1043 89.5028 33.7736 89.3358 34.0009 80.7229C34.0272 72.1101 45.3579 72.2771 45.1306 80.8899Z"
        fill="#49312C"
      />
      <path
        d="M86.4755 86.0227C86.4493 94.6356 75.1186 94.4686 75.3459 85.8558C75.3721 77.2429 86.7029 77.4099 86.4755 86.0227Z"
        fill="#49312C"
      />
      <path
        d="M32.2787 69.9925C33.1005 69.9486 33.7999 69.3685 34.4819 68.9554C35.1813 68.5248 35.872 68.1117 36.6151 67.769C37.2097 67.4965 37.9615 67.2241 38.6785 67.0044C39.0544 66.8901 39.4391 66.7934 39.8238 66.7056C40.2259 66.6177 40.6718 66.6001 41.0565 66.4507C41.6335 66.231 42.0444 65.7212 41.9483 65.0797C41.8521 64.4469 41.3625 64.025 40.7243 63.9899C40.2609 63.9635 39.7451 64.1129 39.2904 64.2272C38.8795 64.3238 38.4774 64.4381 38.0839 64.5611C37.2097 64.8424 36.3616 65.2027 35.5398 65.6246C34.7966 66.0113 34.0797 66.4507 33.4065 66.9516C32.7246 67.4614 31.7541 68.1996 31.6142 69.0961C31.553 69.5091 31.7891 70.0013 32.2787 69.9749V69.9925Z"
        fill="#49312C"
      />
      <path
        d="M87.0437 74.9489C87.2798 74.0436 86.3531 73.4109 85.7148 72.9714C85.1028 72.5496 84.4821 72.1892 83.8001 71.8904C82.3663 71.2577 80.8188 70.6688 79.2451 70.537C78.6069 70.4843 78.0561 71.0731 77.9774 71.6619C77.89 72.2947 78.2747 72.9187 78.9042 73.0857C79.5948 73.2702 80.2943 73.3933 80.9849 73.5866C81.6669 73.78 82.3488 74.0173 83.0046 74.2897C82.8822 74.237 82.9871 74.2897 83.0483 74.3073C83.1269 74.3425 83.2056 74.3776 83.2756 74.4128C83.4854 74.5094 83.704 74.5973 83.9225 74.6852C83.9575 74.7028 84.0012 74.7204 84.0362 74.7292C83.9575 74.694 83.9575 74.694 84.0362 74.7292C84.1149 74.7643 84.1936 74.7995 84.2635 74.8346C84.4209 74.9049 84.5695 74.9752 84.7181 75.0455C85.1116 75.2389 85.54 75.4586 85.9859 75.5026C86.3793 75.5465 86.9126 75.3971 87.035 74.9489H87.0437Z"
        fill="#49312C"
      />
      <path
        d="M20.1785 126.178C18.3775 127.391 17.1185 129.113 16.0868 131.012C15.5798 131.943 15.1688 132.928 14.8279 133.93C14.4869 134.931 14.0498 136.127 14.1022 137.199C14.1547 138.245 15.4748 138.35 15.842 137.436C16.2005 136.54 16.2967 135.52 16.5939 134.598C16.8999 133.657 17.2759 132.752 17.7217 131.873C18.596 130.115 19.7851 128.516 20.8867 126.89C21.1752 126.459 20.5981 125.888 20.1785 126.178Z"
        fill="#49312C"
      />
      <path
        d="M18.9894 133.674C18.4911 134.272 17.9665 134.826 17.5818 135.502C17.1797 136.206 16.8824 136.988 16.6726 137.77C16.2354 139.334 16.1568 141.039 16.5065 142.63C16.6201 143.149 17.2146 143.447 17.7042 143.316C18.2638 143.157 18.4561 142.639 18.3862 142.111C18.2026 140.811 18.2026 139.492 18.4561 138.201C18.5873 137.533 18.7884 136.9 19.0069 136.258C19.2342 135.608 19.4703 134.931 19.8462 134.342C20.1785 133.824 19.3916 133.191 18.9807 133.674H18.9894Z"
        fill="#49312C"
      />
    </svg>
  );
};

export const FeetIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_417_436)">
        <path
          d="M18.5244 17.4436C14.6894 18.303 14.5142 22.061 11.6829 26.0053C7.52994 31.7925 9.33482 35.7467 12.6392 35.9901C15.9435 36.2335 17.9086 32.2023 21.3206 31.6012C21.3306 31.6012 21.3407 31.5963 21.3532 31.5963C21.3632 31.5963 21.3732 31.5913 21.3857 31.5888C24.7727 30.8536 28.1371 33.8341 31.0985 32.3588C34.0599 30.8834 34.2076 26.5442 28.1421 22.7689C24.0092 20.1957 22.3996 16.7879 18.5244 17.4461V17.4436Z"
          fill="#4E3628"
        />
        <path
          d="M8.65145 16.4377C11.365 18.9264 12.2562 22.3541 10.6366 24.0902C9.01944 25.8264 5.5073 25.2154 2.79372 22.7266C0.0801418 20.2354 -0.811034 16.8103 0.808603 15.0741C2.42574 13.3379 5.93787 13.9489 8.65145 16.4377Z"
          fill="#4E3628"
        />
        <path
          d="M27.2634 12.7766C25.7114 16.1049 26.2095 19.6095 28.3724 20.6005C30.5352 21.5916 33.5467 19.6989 35.0963 16.3682C36.6483 13.0399 36.1501 9.53527 33.9873 8.54424C31.8244 7.55321 28.813 9.44585 27.2634 12.7766Z"
          fill="#4E3628"
        />
        <path
          d="M15.4954 7.48363C16.9123 11.0876 16.1638 14.7437 13.8232 15.6478C11.4826 16.5544 8.43607 14.3687 7.0192 10.7647C5.60233 7.16074 6.35082 3.5046 8.69141 2.59802C11.032 1.69144 14.0785 3.87717 15.4954 7.48114V7.48363Z"
          fill="#4E3628"
        />
        <path
          d="M17.4931 7.09119C17.5682 10.9584 19.6684 14.0532 22.1817 14.0036C24.6951 13.9539 26.6702 10.7796 26.5926 6.91236C26.515 3.04511 24.4172 -0.0472082 21.9039 -1.62788e-05C19.3906 0.0496595 17.4155 3.22394 17.4931 7.09119Z"
          fill="#4E3628"
        />
      </g>
      <defs>
        <clipPath id="clip0_417_436">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FeetIcon2 = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_251_2793)">
        <path
          d="M18.5244 17.4436C14.6894 18.303 14.5142 22.061 11.6829 26.0052C7.52993 31.7925 9.33482 35.7466 12.6392 35.9901C15.9435 36.2335 17.9086 32.2023 21.3206 31.6012C21.3306 31.6012 21.3407 31.5962 21.3532 31.5962C21.3632 31.5962 21.3732 31.5913 21.3857 31.5888C24.7727 30.8536 28.1371 33.8341 31.0985 32.3588C34.0599 30.8834 34.2076 26.5442 28.1421 22.7689C24.0092 20.1957 22.3996 16.7879 18.5244 17.4461V17.4436Z"
          fill="#96664A"
        />
        <path
          d="M8.65141 16.4377C11.365 18.9265 12.2562 22.3541 10.6365 24.0902C9.0194 25.8264 5.50727 25.2154 2.79369 22.7266C0.0801056 20.2354 -0.81107 16.8103 0.808567 15.0741C2.4257 13.3379 5.93783 13.9489 8.65141 16.4377Z"
          fill="#96664A"
        />
        <path
          d="M27.2635 12.7766C25.7114 16.1049 26.2096 19.6095 28.3724 20.6005C30.5353 21.5916 33.5468 19.6989 35.0963 16.3682C36.6484 13.0399 36.1502 9.53526 33.9873 8.54423C31.8245 7.55319 28.813 9.44584 27.2635 12.7766Z"
          fill="#96664A"
        />
        <path
          d="M15.4954 7.48365C16.9123 11.0876 16.1638 14.7438 13.8232 15.6479C11.4827 16.5544 8.43613 14.3687 7.01926 10.7647C5.60239 7.16076 6.35088 3.50462 8.69147 2.59804C11.0321 1.69146 14.0786 3.87719 15.4954 7.48116V7.48365Z"
          fill="#96664A"
        />
        <path
          d="M17.4931 7.09121C17.5682 10.9585 19.6685 14.0533 22.1818 14.0036C24.6951 13.9539 26.6702 10.7796 26.5926 6.91238C26.515 3.04512 24.4172 -0.0471911 21.9039 8.87318e-07C19.3906 0.0496766 17.4155 3.22396 17.4931 7.09121Z"
          fill="#96664A"
        />
      </g>
      <defs>
        <clipPath id="clip0_251_2793">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const FeetBottom  = () => (
  <svg
    width="112"
    height="69"
    viewBox="0 0 112 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_0_2)">
      <path
        d="M28.4931 37.0831C26.5688 39.0024 25.193 41.2612 25.4135 44.0326C25.6464 46.9305 27.4283 49.5115 27.7877 52.3971C28.0593 54.6043 27.4937 56.8876 28.0334 59.0433C28.8569 62.3537 32.3684 64.6045 35.7859 64.5032C39.2053 64.3946 42.3558 62.2414 44.2257 59.3752C45.4711 57.459 46.2438 55.2081 47.8207 53.5502C49.5003 51.7965 51.911 50.9091 53.6581 49.2198C55.532 47.4026 56.4912 44.6854 56.1611 42.0927C55.831 39.5 54.2302 37.1062 51.9645 35.8134C49.5844 34.459 46.6979 34.3328 43.9572 34.5539C41.2256 34.7698 38.4889 35.2924 35.7555 35.084C34.3959 34.9817 33.0232 34.6986 31.6681 34.8672C30.313 35.0357 29.3117 36.0696 28.484 37.0884L28.4931 37.0831Z"
        fill="#DBE5F4"
      />
      <path
        d="M8.34788 51.1873C8.60837 52.6594 9.46352 53.9827 10.553 55.0066C11.6425 56.0306 12.9696 56.7714 14.3367 57.392C16.2807 58.275 18.3999 58.9354 20.5183 58.6785C22.6367 58.4217 24.7268 57.0631 25.3136 55.0166C25.9115 52.9577 24.8576 50.6797 23.187 49.3228C21.5216 47.975 19.3577 47.3952 17.2342 47.0959C15.5934 46.8643 13.8997 46.7726 12.3122 47.2334C10.7195 47.685 8.82751 48.2183 8.34596 51.1945"
        fill="#DBE5F4"
      />
      <path
        d="M9.00823 27.3855C6.50637 28.757 6.04625 31.8545 6.60939 34.0088C7.17447 36.1559 8.59522 37.9851 10.2143 39.5054C11.4389 40.658 12.8045 41.6866 14.3345 42.3893C15.8644 43.0921 17.5698 43.4566 19.2485 43.2591C20.3538 43.1315 21.4689 42.7368 22.2336 41.9246C23.4433 40.6307 23.4735 38.62 23.0892 36.8912C22.3391 33.5079 20.2659 30.4328 17.4065 28.48C16.1821 27.6434 14.8064 26.9974 13.3392 26.7506C11.872 26.5039 10.3217 26.6818 9.00823 27.3855Z"
        fill="#DBE5F4"
      />
      <path
        d="M40.4244 15.2149C41.9177 15.3069 43.3341 16.0023 44.4696 16.9692C45.6052 17.9361 46.4998 19.1698 47.2725 20.4556C48.3701 22.2831 49.2657 24.3184 49.2569 26.4504C49.2409 28.5805 48.1346 30.8113 46.1636 31.6394C44.1855 32.4654 41.7993 31.6797 40.2684 30.1753C38.7375 28.671 37.9149 26.5936 37.3703 24.5136C36.9513 22.9142 36.669 21.2359 36.9438 19.6067C37.2113 17.9755 37.531 16.0347 40.4244 15.2149Z"
        fill="#DBE5F4"
      />
      <path
        d="M16.8527 18.5845C17.9297 15.9451 20.9602 15.1313 23.1576 15.4427C25.3549 15.754 27.333 16.9698 29.0313 18.3958C30.3202 19.4808 31.4981 20.7211 32.3694 22.1566C33.2388 23.5993 33.7977 25.2516 33.7929 26.9377C33.7958 28.0481 33.5236 29.208 32.8034 30.0552C31.656 31.4044 29.6603 31.6633 27.8976 31.4838C24.4481 31.1297 21.1622 29.4171 18.89 26.7971C17.9195 25.6741 17.118 24.3806 16.7142 22.9548C16.3031 21.527 16.2987 19.9617 16.8527 18.5845Z"
        fill="#DBE5F4"
      />
    </g>
    <g clip-path="url(#clip1_0_2)">
      <path
        d="M84.2978 24.2559C82.3872 24.7651 80.6987 25.6879 79.8296 27.5142C78.9221 29.4248 79.1059 31.6992 78.2827 33.6481C77.6509 35.1375 76.464 36.3704 76.0198 37.9249C75.3345 40.3103 76.7282 43.0058 78.9183 44.1851C81.1122 45.3606 83.8805 45.15 86.1012 44.0243C87.583 43.27 88.8886 42.1328 90.4853 41.6619C92.1815 41.168 94.0231 41.4857 95.7385 41.0569C97.5802 40.5936 99.173 39.2305 99.9081 37.4769C100.643 35.7233 100.505 33.6328 99.5482 31.994C98.5412 30.2748 96.7684 29.1453 94.9612 28.2877C93.1617 27.43 91.2472 26.7638 89.6008 25.6381C88.7814 25.0791 88.0195 24.4014 87.1044 24.0147C86.1893 23.6279 85.1823 23.9151 84.2902 24.2559H84.2978Z"
        fill="#DBE5F4"
      />
      <path
        d="M66.4745 25.8141C66.1031 26.8364 66.1605 27.9812 66.4745 29.0226C66.7885 30.0641 67.3551 31.0136 67.9907 31.9019C68.8943 33.1655 69.9894 34.3524 71.4176 34.9612C72.8457 35.57 74.6568 35.4743 75.771 34.3984C76.8966 33.3186 77.0613 31.4999 76.5023 30.0373C75.9433 28.5823 74.7908 27.4298 73.5617 26.4688C72.6122 25.726 71.5784 25.0521 70.4106 24.7649C69.2428 24.4701 67.8567 24.1179 66.4707 25.8179"
        fill="#DBE5F4"
      />
      <path
        d="M75.5489 11.0577C73.4737 11.0117 72.057 12.796 71.6282 14.3581C71.2032 15.9165 71.4329 17.5859 71.9 19.1327C72.2523 20.3044 72.7386 21.4492 73.4469 22.4485C74.1552 23.4478 75.0971 24.2979 76.2266 24.7841C76.9694 25.1057 77.8156 25.2627 78.5929 25.0292C79.8258 24.6539 80.5762 23.3981 80.9629 22.169C81.7211 19.7645 81.5334 17.0728 80.4422 14.8023C79.9751 13.8298 79.3433 12.9223 78.5086 12.2331C77.674 11.5439 76.6325 11.0921 75.5489 11.0577Z"
        fill="#DBE5F4"
      />
      <path
        d="M99.7703 14.8176C100.678 15.4187 101.317 16.3721 101.681 17.3944C102.045 18.4167 102.16 19.5194 102.179 20.6107C102.205 22.1614 102.029 23.7695 101.248 25.1096C100.463 26.4459 98.9547 27.449 97.4117 27.2537C95.8649 27.0546 94.6473 25.6916 94.2299 24.1868C93.8126 22.6821 94.05 21.074 94.4635 19.5654C94.7813 18.4053 95.2139 17.2451 95.9797 16.3185C96.7417 15.3881 97.6491 14.2816 99.7703 14.8176Z"
        fill="#DBE5F4"
      />
      <path
        d="M83.6929 8.36612C85.3317 7.09494 87.5371 7.68459 88.8083 8.68009C90.0795 9.67559 90.8835 11.1612 91.4349 12.6774C91.8522 13.8299 92.1432 15.0398 92.17 16.2612C92.193 17.4865 91.9441 18.7308 91.3277 19.7914C90.9256 20.4921 90.3322 21.1239 89.5702 21.3957C88.3565 21.8284 87.0049 21.2656 85.9596 20.5113C83.915 19.0333 82.4677 16.759 81.9891 14.2817C81.7861 13.2211 81.7517 12.1146 82.0159 11.0693C82.2762 10.0202 82.8429 9.03234 83.6929 8.36612Z"
        fill="#DBE5F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect
          width="49.256"
          height="51.4516"
          fill="white"
          transform="translate(0 55.2534) rotate(-75)"
        />
      </clipPath>
      <clipPath id="clip1_0_1">
        <rect
          width="35.8353"
          height="37.4327"
          fill="white"
          transform="translate(59.7357 25.3394) rotate(-45)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const FeetTop = () => (
  <svg
    width="95"
    height="59"
    viewBox="0 0 95 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_0_1)">
      <path
        d="M70.733 26.8166C72.3719 25.182 73.5436 23.2582 73.3558 20.8978C73.1574 18.4297 71.6399 16.2315 71.3337 13.7739C71.1024 11.8941 71.5841 9.94947 71.1245 8.11352C70.4231 5.29405 67.4324 3.37708 64.5218 3.46342C61.6096 3.55589 58.9263 5.38971 57.3338 7.83079C56.2731 9.4628 55.615 11.3799 54.272 12.7919C52.8415 14.2854 50.7884 15.0412 49.3004 16.48C47.7044 18.0277 46.8875 20.3419 47.1686 22.55C47.4498 24.7581 48.8131 26.7969 50.7428 27.898C52.7699 29.0515 55.2283 29.1589 57.5625 28.9706C59.8889 28.7868 62.2197 28.3417 64.5478 28.5192C65.7056 28.6064 66.8748 28.8474 68.0289 28.7039C69.183 28.5603 70.0358 27.6798 70.7408 26.8121L70.733 26.8166Z"
        fill="#DBE5F4"
      />
      <path
        d="M87.8903 14.8043C87.6684 13.5505 86.9401 12.4234 86.0122 11.5514C85.0843 10.6793 83.954 10.0483 82.7897 9.51981C81.1341 8.76775 79.3292 8.20537 77.5249 8.42411C75.7207 8.64285 73.9407 9.79994 73.4409 11.5429C72.9316 13.2964 73.8292 15.2366 75.2521 16.3922C76.6704 17.5401 78.5134 18.0339 80.322 18.2888C81.7194 18.4861 83.1619 18.5641 84.5139 18.1717C85.8704 17.7871 87.4818 17.3329 87.8919 14.7982"
        fill="#DBE5F4"
      />
      <path
        d="M87.3279 35.0759C89.4586 33.9078 89.8505 31.2697 89.3709 29.435C88.8896 27.6063 87.6796 26.0484 86.3006 24.7536C85.2577 23.7719 84.0946 22.8959 82.7916 22.2974C81.4885 21.6989 80.0361 21.3884 78.6064 21.5566C77.6651 21.6653 76.7153 22.0014 76.064 22.6932C75.0338 23.7952 75.008 25.5077 75.3354 26.9801C75.9742 29.8615 77.7399 32.4806 80.1752 34.1437C81.218 34.8563 82.3896 35.4065 83.6393 35.6166C84.8889 35.8267 86.2092 35.6752 87.3279 35.0759Z"
        fill="#DBE5F4"
      />
      <path
        d="M60.5713 45.4415C59.2995 45.3632 58.0932 44.7709 57.126 43.9474C56.1589 43.1239 55.397 42.0732 54.7389 40.9781C53.804 39.4217 53.0413 37.6882 53.0488 35.8725C53.0624 34.0583 54.0046 32.1583 55.6833 31.4531C57.368 30.7495 59.4003 31.4187 60.7041 32.7C62.008 33.9812 62.7086 35.7505 63.1724 37.5219C63.5292 38.8841 63.7697 40.3135 63.5357 41.7011C63.3078 43.0904 63.0355 44.7433 60.5713 45.4415Z"
        fill="#DBE5F4"
      />
      <path
        d="M80.6469 42.5717C79.7296 44.8197 77.1486 45.5128 75.2771 45.2476C73.4057 44.9824 71.721 43.9469 70.2746 42.7324C69.1769 41.8083 68.1737 40.752 67.4316 39.5294C66.6911 38.3007 66.2151 36.8935 66.2192 35.4574C66.2167 34.5118 66.4486 33.5239 67.0619 32.8023C68.0392 31.6533 69.7389 31.4328 71.2401 31.5856C74.178 31.8872 76.9765 33.3458 78.9117 35.5772C79.7383 36.5336 80.4209 37.6353 80.7649 38.8496C81.115 40.0656 81.1187 41.3988 80.6469 42.5717Z"
        fill="#DBE5F4"
      />
    </g>
    <g clip-path="url(#clip1_0_1)">
      <path
        d="M23.205 37.7411C24.8323 37.3074 26.2703 36.5215 27.0106 34.966C27.7834 33.3388 27.6269 31.4018 28.328 29.742C28.8661 28.4735 29.877 27.4234 30.2552 26.0995C30.839 24.0679 29.652 21.7722 27.7867 20.7678C25.9182 19.7667 23.5605 19.946 21.6691 20.9047C20.4071 21.5472 19.2951 22.5157 17.9353 22.9168C16.4907 23.3374 14.9222 23.0668 13.4613 23.432C11.8927 23.8266 10.5362 24.9875 9.91006 26.481C9.28395 27.9745 9.40134 29.755 10.2166 31.1507C11.0742 32.6149 12.584 33.5769 14.1232 34.3073C15.6559 35.0378 17.2864 35.6052 18.6886 36.5639C19.3864 37.04 20.0354 37.6172 20.8147 37.9466C21.5941 38.2759 22.4517 38.0314 23.2116 37.7411L23.205 37.7411Z"
        fill="#DBE5F4"
      />
      <path
        d="M38.3848 36.414C38.7012 35.5434 38.6522 34.5683 38.3848 33.6813C38.1174 32.7944 37.6348 31.9856 37.0935 31.2291C36.3239 30.153 35.3913 29.1421 34.1749 28.6236C32.9586 28.1051 31.4161 28.1866 30.4672 29.1029C29.5085 30.0225 29.3683 31.5715 29.8444 32.8172C30.3205 34.0564 31.302 35.0379 32.3488 35.8564C33.1575 36.489 34.038 37.063 35.0326 37.3075C36.0272 37.5586 37.2076 37.8586 38.3881 36.4108"
        fill="#DBE5F4"
      />
      <path
        d="M30.6564 48.9817C32.4238 49.0208 33.6304 47.5012 33.9956 46.1707C34.3576 44.8435 34.1619 43.4217 33.7641 42.1043C33.4641 41.1064 33.0499 40.1314 32.4466 39.2803C31.8434 38.4292 31.0412 37.7052 30.0792 37.2911C29.4465 37.0172 28.7259 36.8835 28.0639 37.0824C27.0139 37.402 26.3747 38.4716 26.0453 39.5183C25.3997 41.5662 25.5595 43.8587 26.4888 45.7924C26.8867 46.6207 27.4247 47.3936 28.1356 47.9805C28.8465 48.5675 29.7335 48.9523 30.6564 48.9817Z"
        fill="#DBE5F4"
      />
      <path
        d="M10.0274 45.7796C9.25459 45.2676 8.71 44.4556 8.40021 43.585C8.09042 42.7143 7.99259 41.7751 7.97629 40.8457C7.95346 39.525 8.10346 38.1554 8.7687 37.0141C9.4372 35.876 10.722 35.0216 12.0362 35.188C13.3536 35.3575 14.3906 36.5184 14.7461 37.8C15.1015 39.0816 14.8993 40.4512 14.5471 41.736C14.2765 42.7241 13.908 43.7121 13.2558 44.5013C12.6069 45.2937 11.834 46.2361 10.0274 45.7796Z"
        fill="#DBE5F4"
      />
      <path
        d="M23.7203 51.2743C22.3246 52.357 20.4463 51.8548 19.3636 51.0069C18.281 50.1591 17.5962 48.8938 17.1266 47.6025C16.7711 46.6209 16.5233 45.5905 16.5005 44.5502C16.4809 43.5067 16.6929 42.4469 17.2179 41.5436C17.5603 40.9468 18.0658 40.4088 18.7147 40.1772C19.7484 39.8088 20.8995 40.2881 21.7898 40.9305C23.5311 42.1893 24.7638 44.1263 25.1714 46.2361C25.3442 47.1394 25.3736 48.0818 25.1486 48.9721C24.9268 49.8656 24.4442 50.7069 23.7203 51.2743Z"
        fill="#DBE5F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect
          width="41.9504"
          height="43.8204"
          fill="white"
          transform="translate(95 11.3413) rotate(105)"
        />
      </clipPath>
      <clipPath id="clip1_0_1">
        <rect
          width="30.5203"
          height="31.8807"
          fill="white"
          transform="translate(44.1241 36.8184) rotate(135)"
        />
      </clipPath>
    </defs>
  </svg>
);